
<template>
        <div>
            <div class="row">
                <div class="col-lg-2"></div>
                <div class="col-lg-8">
                    <div class="box">
                        <div class="box-header with-border">
                        <h3 class="box-title">Přidat záznam o osobě</h3>
                        </div>
                        <!-- /.box-header -->
                        <!-- form start -->
                        <form role="form">
                        <div class="box-body">
                            <div class="form-horizontal">
                                <div :class="{'form-group': true, 'has-error': $v.formResponses.fullname.$error}">
                                    <label for="inputName" class="col-sm-2 control-label">Jméno a příjmení</label>

                                    <div class="col-sm-10">
                                    <input id="inputName" class="form-control"  v-model="$v.formResponses.fullname.$model" type="text">
                                    <span v-if="!$v.formResponses.fullname.required" class="help-block">Povinný údaj</span>
                                    <span v-if="!$v.formResponses.fullname.maxLength" class="help-block">Může mít maximálně 32 znaků!</span>
                                    </div>
                                </div>
                                <div :class="{'form-group': true, 'has-error': $v.formResponses.customerid.$error}">
                                    <label for="selectCus" class="col-sm-2 control-label">Zákazník</label>

                                    <div class="col-sm-10">
                                    <Select2 v-model="$v.formResponses.customerid.$model" :options="minCustomers" id="selectCus"/>
                                    <span v-if="!$v.formResponses.customerid.required" class="help-block">Povinný údaj</span>
                                    </div>
                                </div>
                                <div :class="{'form-group': true, 'has-error': $v.formResponses.contact_email.$error}">
                                    <label for="inputEmail" class="col-sm-2 control-label">Email</label>

                                    <div class="col-sm-10">
                                    <input type="text" class="form-control" id="inputEmail" v-model.lazy="$v.formResponses.contact_email.$model">
                                    <span v-if="!$v.formResponses.contact_email.required" class="help-block">Povinný údaj</span>
                                    <span v-if="!$v.formResponses.contact_email.email" class="help-block">Musí mít formát emailové adresy!</span>
                                    </div>
                                </div>
                                <div :class="{'form-group': true, 'has-error': $v.formResponses.contact_phone.$error}">
                                    <label for="inputPhone" class="col-sm-2 control-label">Telefonní číslo</label>

                                    <div class="col-sm-10">
                                    <input type="text" class="form-control" id="inputPhone" v-model.lazy="$v.formResponses.contact_phone.$model">
                                    <span v-if="!$v.formResponses.contact_phone.phone_format" class="help-block">Musí mít formát telefonního čísla a minimálně 9 znaků.</span>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <div class="col-sm-offset-2 col-sm-10">
                                    <div class="checkbox">
                                        <label>
                                        <input v-model="canlog" type="checkbox"> Vytvořit a zaslat přihlašovací údaje této osobě na email.                                    
                                        </label>
                                    </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="col-sm-offset-2 col-sm-10">
                                    <div class="checkbox">
                                        <label>
                                        <input v-model="admin" type="checkbox" :disabled="!canlog"> Práva správce.                                    
                                        </label>
                                        <p>Toto umožní uživateli vidět všechna auta jeho společnosti.</p>
                                    </div>
                                    </div>
                                </div>

                            </div>
                            
                        </div>
                        <div class="box-footer">
                            <div class="form-group">
                                <div class="col-sm-offset-2 col-sm-10">
                                    <div class="checkbox">
                                        <label>
                                        <input v-model="dontClosePage" type="checkbox"> Budu přidávat dál
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-sm-offset-2 col-sm-10">
                                    <div  @mouseenter="$v.formResponses.$touch()">
                                        <button type="button" class="btn btn-success" @click="submitForm()" :disabled="$v.formResponses.$invalid">{{canlog ? 'Přidat a odeslat' : 'Přidat'}}</button>
                                    </div>
                                    <button type="button" class="btn btn-default pull-right" @click="closeTab(page.id)">Zrušit</button>
                                </div>
                            </div>
                        </div>
                        </form>
                        
                    </div>
                </div>
            </div>
            
        </div>
</template>

<script>
const name = 'MaintainerAdd';

// Vuelidate
import { required,  maxLength, email, helpers, requiredIf } from 'vuelidate/lib/validators'

const phone_format = helpers.regex('phone_format', /^[+]?[()/0-9. -]{9,}$/)


// Select2
import Select2 from '../components/pageux/Select2';

import { mapGetters, mapActions } from "vuex";

export default {
    name,
    components: { 
        Select2,
    },
    data() {
        return {
            page: null,
            dontClosePage: false,

            errors: [],
            success: false,

            canlog: false,
            admin: false,

            formResponses: {
                fullname: '',
                customerid: '',
                contact_email: '',
                contact_phone: '',
            }
        }
    },
    validations: {
        formResponses: {
            fullname: {
                required,
                maxLength: maxLength(32)
            },
            customerid: {
                required
            },
            contact_email: {
                email,
                required: requiredIf(function() {
                    return this.canlog
                })
            },
            contact_phone: {
                phone_format
            }
        }
    },
    computed: mapGetters(["getAllPages", 'minCustomers']),
    methods: {
        ...mapActions(["makeActiveTab", "addTab", "removeTab", 'closeTab',"addMaintainer", 'makeCurrentMnt', 'fetchMinCustomers']),

        getDate(t){
            const dt = new Date(t*1000);
            const day = dt.getDate();
            const mth = dt.getMonth() + 1;
            const yrs = dt.getFullYear();
            return `${day}. ${mth}. ${yrs}`;  
        },
        async submitForm() {            

            if (this.$v.formResponses.$invalid) 
                return 0;

            this.errors = [];
            
            const values = {
                fullname: this.formResponses.fullname,
                customerid: this.formResponses.customerid,
                email: this.formResponses.contact_email,
                phone: this.formResponses.contact_phone,
                canlog: this.canlog,
                admin: this.admin
            }

            const res = await this.addMaintainer(values);

            // Handling response
            if(res.existing !== undefined){
                this.$alerts.message('Záznam již existuje.', "", 'error');
                return false;
            } else if (res.msg == "Success."){
                this.$alerts.toast('Záznam osoby úspěšně vytvořen.', 'success');
                if (this.dontClosePage){
                    // Clear data 
                    this.formResponses.fullname = "";
                    this.formResponses.contact_email = "";
                    this.formResponses.contact_phone = "";
                    this.$nextTick(() => { this.$v.$reset() })
                }
                else{
                    this.closeTab(this.page.id);
                    this.makeCurrentMnt(res.id);
                }   
            }
            else   
                this.$alerts.message('Nastala chyba', res.msg, 'error');            

            Object.keys(this.values).forEach(k => this.values[k] = null);
        }
    },
    async created(){
        if (this.$route.params.customerid)
            this.formResponses.customerid = this.$route.params.customerid;

        this.fetchMinCustomers();

        this.page = this.getAllPages.filter(page => page.name == name)[0]   
        this.addTab(this.page);
        this.makeActiveTab(this.page);
    }
}
</script>

<style scoped>
a {
    cursor: pointer;
}
</style>